<template>
   <div class="xwdt">
    <img src="../assets/xwdt/banner.png" class="banner" />
    <div class="rows rows1">
      <img src="../assets/xwdt/xwdt1.png" />
      <img src="../assets/xwdt/xwdt2.png" />
    </div>
    <div class="rows rows2">
      <img src="../assets/xwdt/xwdt3.png" />
      <img src="../assets/xwdt/xwdt4.png" />
      <img src="../assets/xwdt/xwdt5.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'xwdt',
  components: {}
};
</script>
<style lang="less">
.xwdt {
  .banner {
    width: 100%;
    display: block;
  }
  .bannerSj {
    width: 0;
    height: 0;
    border-left: 85/19.2vw solid transparent;
    border-right: 85/19.2vw solid transparent;
    border-top: 85/19.2vw solid #007dd3;
    margin: 0 auto;
  }
  .rows {
    display: flex;
    width: 1328/19.2vw;
    justify-content: space-between;
    margin: 24/19.2vw auto;
  }
  .rows1 {
    margin-top: 110/19.2vw;
    img {
      width: 654/19.2vw;
      height: 364/19.2vw;
    }
  }
  .rows2 {
    margin-bottom: 110/19.2vw;
    img {
      width: 432/19.2vw;
      height: 364/19.2vw;
    }
  }
}

</style>
